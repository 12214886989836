.App {
  text-align: center;
}

header h1 {
  position: absolute;
}
header h1 span {
  display: block;
  color: #9e9e9e;
}

header .header-button {
  border: 2px solid #fff;
  border-top-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255);
  box-sizing: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 16pt;
  padding: 10px 20px;
  transition: all 0.5s;
  z-index: 1000;
}

header .header-button:hover {
  background-color: #c80000BD;
  border-color: #C80000BD;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.section h1{
  display: grid;
  text-transform: uppercase;
  color: #444444;
  align-self: flex-start;
  justify-content: left;
  border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
border-bottom: 1px solid #eee;
}

.section {
  margin-bottom: 45px;
}

@media(min-width: 768px){
  .spacebar.fixed {
    position: relative;
    min-height: 56px;

  }
}
.section h2, .section h3{
  margin-top: 20px;
  display: grid;
  text-transform: uppercase;
  color: #444444;
  align-self: flex-start;
  justify-content: left;

}

.svg-perfil {
  width: 250px;
}



@media(min-width: 768px) {
  .grid-perfil-item {
    grid-row: 1;
  }
  .section p {
    text-align: justify;
  }
}


.social_icons {

  list-style: none;
  display: flex;
  flex-flow: row nowrap;

}

.social_icons li {
  margin: 0.15em;
  position: relative;
  font-size: 1em;
}

.social_icons i {
  color: #fff;
  position: absolute;
  top: 1em;
  transition: all 265ms ease-out;
}


.social_icons a:before {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  content: " ";
  width: 45px;
  height: 45px;
  display: block;
  transition: all 265ms ease-out;
}

.social_icons a:hover:before {
  transform: scale(0);
  transition: all 265ms ease-in;
}

.social_icons a:hover i {
  transform: scale(2.2);
  -ms-transform: scale(2.2);
  -webkit-transform: scale(2.2);
  color: #c80000;
  background: -webkit-linear-gradient(45deg, #c80000, #e80000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 265ms ease-in;
}